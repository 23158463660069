@tailwind base;
@tailwind components;
@tailwind utilities;


[aria-current="page"] {
  @apply
  bg-blue-600
  text-white
  hover:bg-blue-700
  hover:text-white
}

.date-table .today{
  @apply
  text-blue-500
  hover:text-white
}

.date-table .prev-month, .date-table .next-month{
  @apply
  text-gray-300
}

.date-table .in-range{
  @apply
  bg-blue-500
  text-white
}

.date-table .start-date{
  @apply
  bg-blue-700
  text-white
}

.date-table .end-date{
  @apply
  bg-blue-700
  text-white
}

.flow-input__inner {
  @apply
  bg-gray-100
  focus:outline-none
  w-full
  text-sm
  mr-2
}

.blackout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
}
.spinner:empty {
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid #113FB4;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

 .react-calendar-heatmap text {
  @apply
  text-xs
  fill-current
  text-gray-200
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}


@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: #3b82f6;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.react-datepicker__input-container {
  @apply
  h-full
}

.react-datepicker__day-names, .react-datepicker__week {
  @apply
  grid
  grid-cols-7
}
.react-datepicker-popper {
  @apply
  z-50
}

.react-datepicker {
  @apply
  bg-white
  p-4
  shadow-lg
  border
  border-gray-100
  mt-4
}
.react-datepicker.only_month {
  @apply
  w-64
}

.react-datepicker.date_range {
  @apply
  flex
  gap-x-8
}

.react-datepicker__day--outside-month {
  @apply
  text-sm
  text-gray-500
}

.react-datepicker__day--range-start {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  rounded-l
}

.react-datepicker__day--range-end {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  rounded-r
}

.react-datepicker__day--in-range {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
}

.react-datepicker.intensive {
  @apply
  rounded-sm
}

.react-datepicker.intensive .react-datepicker__day--today {
  @apply
  bg-white
}

.react-datepicker__day--today {
  @apply
  bg-gray-200
  text-sm
  text-gray-800
  rounded
  font-normal
}

.react-datepicker__day--in-range.react-datepicker__day--today {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  font-normal
  rounded-none
}

.react-datepicker__day--selected {
  @apply
  bg-blue-200
  text-sm
  text-blue-600
  rounded
  font-medium
}

.react-datepicker__day--in-range.react-datepicker__day--selected {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  font-normal
  rounded-none
}

.react-datepicker__day--in-range.react-datepicker__day--selected.react-datepicker__day--range-start {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  font-normal
  rounded-l
}

.react-datepicker__day--in-range.react-datepicker__day--selected.react-datepicker__day--range-end {
  @apply
  bg-blue-100
  text-sm
  text-blue-600
  font-normal
  rounded-r
}

.react-datepicker__day--selected.react-datepicker__day--in-selecting-range {
  @apply
  rounded-none
}

.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
  @apply
  rounded-l
}

.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  @apply
  rounded-r
}

.react-datepicker__day--in-range.react-datepicker__day--outside-month {
  @apply
  bg-white
}
.react-datepicker__day--today.react-datepicker__day--outside-month {
  @apply
  bg-white
}

.react-datepicker__day--in-selecting-range {
  @apply
  bg-blue-600
  text-sm
  text-white
}

.react-datepicker__day--selecting-range-start {
  @apply
  bg-blue-600
  text-sm
  text-white
  rounded-l
}

.react-datepicker__day--selecting-range-end {
  @apply
  bg-blue-600
  text-sm
  text-white
  rounded-r
}

.react-datepicker__monthPicker {
  @apply
  text-sm
}
.react-datepicker__month-text {
  @apply
  text-center
  py-1
  my-1
  hover:bg-blue-600
  hover:text-white
  rounded
}
.react-datepicker__month--selected {
  @apply
  bg-blue-600
  text-white
}

.react-datepicker__day-name, .react-datepicker__day {
  @apply
  flex
  w-8
  h-8
  text-sm
  items-center
  justify-center
}

.react-datepicker__monthPicker {
  @apply
  w-full
}

.flow_select__control {
  @apply
  ring 
  ring-gray-200;
  border: 0 !important;
  border-radius: 2px !important;
  /* height: 2rem !important; */
  /* min-height: 32px !important; */
}
.flow_select__control.flow_select__control--is-focused {
  @apply
  ring 
  ring-blue-300;
}

.canvas_js_tooltip_base_styles{
  @apply pointer-events-auto font-sans;
}